<template>
  <form @submit.prevent="handleValidate">
    <b-card>
      <b-row>
        <b-col>
          <basic-input
            label="Mã bộ câu hỏi"
            placeholder="Nhập mã bộ câu hỏi"
            name="code"
            :value.sync="form.code"
            :required="true"
            :state="validateState('code')"
            :invalidFeedback="errors.first('code')"
            v-validate="'required|alpha_num|max:50'"
            data-vv-as="Mã bộ câu hỏi"
            maxlength="50"
          ></basic-input>
        </b-col>
        <b-col>
          <basic-input
            label="Tên bộ câu hỏi"
            placeholder="Nhập tên bộ câu hỏi"
            name="name"
            :value.sync="form.name"
            :required="true"
            :state="validateState('name')"
            :invalidFeedback="errors.first('name')"
            v-validate="'required|max:100'"
            data-vv-as="Tiêu đề bộ câu hỏi"
            maxlength="100"
          ></basic-input>
        </b-col>
        <b-col v-if="id">
          <basic-select
            label="Trạng thái"
            placeholder="--- Chọn trạng thái ---"
            name="status"
            :solid="false"
            :allowEmpty="true"
            value-label="text"
            track-by="value"
            :options="statuses"
            :value.sync="form.status"
            disabled
          />
        </b-col>
      </b-row>
    </b-card>
    <basic-subheader title="Biên tập câu hỏi" class="my-6">
      <template v-slot:actions>
        <b-button
          v-if="hasQuestions"
          class="btn btn-green ml-3"
          type="button"
          @click="preview"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/eye.svg" />
          </span>
          Xem trước
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="button"
          @click="addQuizeQuestion"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm câu hỏi tại quiz
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="button"
          @click="addQuestion"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm câu hỏi
        </b-button>
      </template>
    </basic-subheader>
    <b-overlay :show="loading">
      <b-card>
        <template v-if="hasQuestions">
          <template v-for="question in questions">
            <Question
              :key="question.id"
              :id="question.id"
              :question="question"
              @edit="editQuestion"
              @delete="deleteQuestion"
            />
          </template>
        </template>
        <div v-else class="text-center">Không có bất kỳ câu hỏi nào.</div>
      </b-card>
    </b-overlay>
    <ModalQuestion
      v-if="showModal"
      :type="typeModal"
      :question="questionDetail"
      :id="id"
      @reset="resetModal"
    />
    <ModalPreview v-if="showModalPreview" @reset="rresetModalPreview" />
    <ModalAddQuize
      v-if="showModalAddQuize"
      :id="id"
      @reset="resetModalAddQuize"
    />
  </form>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('survey');
import { MODAL_TYPE } from '@/core/plugins/constants';
import { EDIT_QUESTION_GROUP } from '@/core/services/store/survey.module';
import { sortBy } from 'lodash';
import { MODAL_STATUS } from '../../../../core/plugins/constants';
export default {
  name: 'FormQuestion',
  components: {
    ModalQuestion: () => import('./ModalQuestion.vue'),
    ModalPreview: () => import('./ModalPreview.vue'),
    ModalAddQuize: () => import('./ModalAddQuize.vue'),
    Question: () => import('./Question.vue'),
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
      showModalPreview: false,
      showModalAddQuize: false,
      loading: false,
      typeModal: null,
      form: {
        code: null,
        name: null,
        status: null,
      },
      questionDetail: null,
      statuses: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'Inactive',
          value: 0,
        },
        {
          text: 'Draft',
          value: 2,
        },
      ],
    };
  },
  computed: {
    ...mapState(['questions']),
    hasQuestions() {
      return this.questions.length > 0;
    },
  },
  watch: {
    data: {
      handler() {
        this.setupData();
      },
      deep: true,
    },
  },
  created() {
    this.setupData();
  },
  methods: {
    ...mapActions({ EDIT_QUESTION_GROUP }),
    setupData() {
      if (this.data) {
        this.form = { ...this.data };
        this.form.status = this.statuses.find(
          (el) => el.value === this.data.status,
        );
      }
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit();
        }
        return;
      });
    },
    getModelStatus(question) {
      if (question.isEdit) return MODAL_STATUS.CHANGED;
      return [2, 3].includes(question.modelStatus)
        ? question.modelStatus
        : MODAL_STATUS.CHANGED;
    },
    submit() {
      let params = {
        code: this.form.code,
        name: this.form.name,
        id: this.id,
      };
      this.$emit('submit', params);
    },
    getParamsAnswer(question) {
      let answers = [...question.answers, ...question.deletedAnswer];
      answers = answers.map((answer) => {
        return {
          ...answer,
          modelStatus: answer.modelStatus,
          surveyQuestionId: question.id,
        };
      });
      return answers;
    },
    addQuestion() {
      this.typeModal = MODAL_TYPE.CREATE;
      this.showModal = true;
      this.$store.commit(`context/setModal`, true);
    },
    addQuizeQuestion() {
      this.showModalAddQuize = true;
      this.$store.commit(`context/setModal`, true);
    },
    preview() {
      this.showModalPreview = true;
      this.$store.commit(`context/setModal`, true);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    editQuestion(params) {
      this.typeModal = MODAL_TYPE.EDIT;
      this.showModal = true;
      this.questionDetail = {
        ...params,
        answers: sortBy(params.answers, 'order'),
      };
      this.$store.commit(`context/setModal`, true);
    },
    showPopupWarning(params) {
      this.$swal({
        title: 'Xác nhận',
        text: `Câu hỏi này là điều kiện hiển thị của câu hỏi ${params.name}`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'OK',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(function () {}.bind(this));
    },
    resetModal() {
      this.typeModal = null;
      this.showModal = false;
      this.questionDetail = null;
    },
    resetModalAddQuize() {
      this.showModalAddQuize = false;
    },
    rresetModalPreview() {
      this.showModalPreview = false;
    },
    async deleteQuestion(params) {
      const reletedQuestion = this.questions.find(
        (el) => el.mappedQuestionId === params.id,
      );
      if (reletedQuestion) {
        return this.showPopupWarning(reletedQuestion);
      }
      this.loading = true;
      params.modelStatus = MODAL_STATUS.DELETE;
      params.answers = params.answers.map((el) => ({
        ...el,
        modelStatus: MODAL_STATUS.DELETE,
      }));
      const payload = {
        id: this.id,
        name: null,
        code: null,
        questions: params,
      };
      const questionIndex = this.questions.findIndex(
        (el) => el.id === params.id,
      );

      this.questions[questionIndex] = params;
      payload['questions'] = this.questions;
      const { error } = await this.EDIT_QUESTION_GROUP(payload);
      if (error) {
        this.loading = false;
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.font-size-xl {
  font-size: 1.25rem;
}

.btn-green {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}
</style>
